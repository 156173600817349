import React, { useState } from 'react';
import Header from './Header';
// import Modal from 'react-modal';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faBook, faArrowRight, faArrowLeft, faRuler, faArrowUpRightDots, faChartLine, faBinoculars } from '@fortawesome/free-solid-svg-icons'
// import ToDoList from './ToDoList';
// import BookCase from './BookCase';
import StepTitle from './StepTitle';
import UploadData from './UploadData';
import GetColumns from './GetColumns';
import GetCalculations from './GetCalculations';
import SetScenario from './SetScenario';
import '../CSS/Canvas.css';

// const hostname = 'http://127.0.0.1:8000/'
// const hostname = 'https://3.145.167.170/'
// const hostname = 'http://3.145.167.170/'
// const hostname = 'http://3.142.133.39/'
// const hostname = 'http://18.188.239.119/';
const hostname = 'https://api.evalution.app/'
console.log('HOSTNAME IS ', hostname);

function Canvas() {
    const [step, setStep] = useState(0);
    const [OKmoveOn, setOKmoveOn] = useState(true);
    const [viewToDo, setViewToDo] = useState(false);
    const [viewBook, setViewBook] = useState(false);
    const [viewOutline, setViewOutline] = useState(false)
    const [data, setData] = useState();
    const [columns, setColumns] = useState();
    const [outcome, setOutcome] = useState();
    const [metrics, setMetrics] = useState();
    const [positiveClass, setPositiveClass] = useState('');
    const [, setMeans] = useState();
    const [, setCorrelations] = useState();
    const [, setModel] = useState();
    const [, setScenario] = useState();
    const [keyMetric, setKeyMetric] = useState();
    const [contextualMetrics, setContextualMetrics] = useState(false);
    const [contextualMetricCategory, setContextualMetricCategory] = useState();

    var payload = {};
    payload['metrics'] = metrics;
    payload['outcome'] = outcome;
    payload['data'] = data;
    payload['positiveClass'] = positiveClass;
    payload['keyMetric'] = keyMetric;
    payload['contextualMetrics'] = contextualMetrics;
    // payload['contextualMetricCategory'] = contextualMetricCategory;

    const sampleFile = [
        ["Menu Item", "Category", "Average Rating"],
        ["Salad", "Healthy Eats", "4"],
        ["Smoothie", "Somewhat Healthy Eats", "6"],
        ["Ice Cream", "Not Healthy Eats", "9"]
      ];
    function toggleToDo() {
        setViewToDo(!viewToDo);
    }
    function toggleViewBook() {
        setViewBook(!viewBook);
    }
    function moveOn() {
        if (step<8)
            {setStep(step+1);
             setOKmoveOn(false);}
    }
    function goBack(){
        if (step>0)
            {setStep(step-1);
             setOKmoveOn(true);}
    }

    return (
        <>
        <Header/>

        {/* <Modal isOpen={viewToDo} onRequestClose={toggleToDo} className='popUp'>
            <ToDoList toggleToDo={toggleToDo}/>
        </Modal>
        
        <Modal isOpen={viewBook} onRequestClose={toggleViewBook} className='popUp'>
            <BookCase toggleViewBook={toggleViewBook}/>
        </Modal> */}
        
        {/* <FontAwesomeIcon className='toDoListIcon' icon={faClipboardList} onClick={toggleToDo} title='Track your progress.'/>
        <FontAwesomeIcon className='help' icon={faBook} onClick={toggleViewBook} title='Peruse some information.'/> */}

        <div className='canvasGrid'>
            <div className='leftTool'/>
            <div className='mainspace'>
                <div className='workspace'>
                    
                    {step === 0 ? 
                    <>
                        <div className='workTitle'>
                            <StepTitle message='Welcome.'/>
                        </div>
                        { viewOutline ?
                        <ol className='overviewOutline'>
                            <li className='outlineItem' data-hover='&nbsp;&nbsp;&nbsp;Locate your data and load it into the application.'>
                                <span>
                                    Find and load your data. 
                                </span>
                            </li>

                            <li className='outlineItem' data-hover='&nbsp;&nbsp;&nbsp;Pick the columns within your data that you’d like to use in the analysis.'>
                                <span>
                                    Review your data and select your metrics.
                                </span>
                            </li>
                            
                            <li className='outlineItem' data-hover='&nbsp;&nbsp;&nbsp;Review how each column looks through a univariate analysis.'>
                                <span>
                                    Take a look at your data!
                                </span>
                            </li>

                            <li className='outlineItem' data-hover='&nbsp;&nbsp;&nbsp;This is achieved through a multivariate analysis.'>
                                <span>
                                    View potential relationship between metric and outcome.
                                </span>
                            </li>

                            <li className='outlineItem' data-hover='&nbsp;&nbsp;&nbsp;Take a look at the whole picture.'>
                                <span>
                                    Create model to understand outcome.
                                </span>
                            </li>

                            <li className='outlineItem' data-hover='&nbsp;&nbsp;&nbsp;Create a scenario to make predictions using your model.'>
                                <span>
                                    Put model into action to explore outcome in different scenarios.
                                </span>
                            </li>

                            <li className='outlineItem' data-hover='&nbsp;&nbsp;&nbsp;Review what actions may positively influence your outcome.'>
                                <span>
                                    All aboard the data driven action express.
                                </span>
                            </li>

                        </ol>
                        :
                            <div className='workItem'>
                                <p>Together, we will ask questions of data.</p> 
                                <p>We will flow through each step one at a time.</p>
                                <p>Please use the arrow below to navigate through each step.</p>
                                <a 
                                    // className='calculationButton' 
                                    className='viewOverviewButton'
                                    onClick={() => setViewOutline(true)}
                                >
                                    See Overview.
                                </a>
                            </div>
                        }


                    </>
                    : null}

                    {step === 1 ? 
                    <>
                        <div className='workTitle'>
                            <StepTitle step={step} message='find and load your data'/>
                        </div>
                        <div className='workItem'>
                        {data ? 
                            <>
                            <p>Great, your file is uploaded. Please click the forward arrow to select the subset of columns you would like to take a look at.</p>
                            <p>(you can upload a different file by selecting "upload a file" again)</p>
                            </>
                            :
                            <>
                                <p>We will start by selecting a file to upload.</p>
                                <p>Please select an excel file that contains the data you would like to analyze.</p>
                                <p>The first row of your file should contain the name of each column. <CSVLink data={sampleFile} filename={"my-sample-file.csv"} className='sampleLink'>view sample file</CSVLink></p>
                                {/* <UploadData setData={setData} data={data} setOKmoveOn={setOKmoveOn}/> */}
                            </> }
                        <UploadData 
                            setData={setData} 
                            data={data}
                            setColumns={setColumns}
                            setOutcome={setOutcome}
                            setMetrics={setMetrics}
                        />
                        </div>

                    </>
                    : null}
                    {step === 2 ?
                    <>
                        <div className='workTitle'>
                            <StepTitle step={step} message='review your data and select your metrics'/>
                        </div>
                        <div className='workItem'>
                            {columns ?
                            null
                            :
                            <p>To select the columns that contain the information that you care about, click "see columns".</p>}
                            <GetColumns data={data} 
                                        hostname={hostname}
                                        setColumns={setColumns}
                                        columns={columns}
                                        setOutcome={setOutcome}
                                        outcome={outcome}
                                        setMetrics={setMetrics}
                                        metrics={metrics}
                                        positiveClass={positiveClass}
                                        setPositiveClass={setPositiveClass}
                                        setOKmoveOn={setOKmoveOn}/>
                        </div>
                    </>
                    : null}
                    {step === 3 ?
                    <>
                        <div className='workTitle'>
                            <StepTitle step={step} message='take a look at your data!'/>      
                        </div>
                        <div className='workItem'>                                     
                            <GetCalculations post={hostname + 'get_descriptives'} 
                                            payload={payload} 
                                            setState={setMeans}
                                            icon={faRuler}
                                            buttonText='view data'
                                            type='Descriptives'
                                            loadingMessage='loading univariate statistics'>   
                                <p>For our outcome and each of our metrics, we will perform a “univariate” analysis.</p>
                                <p>Univariate means "one variable". We look at one metric at a time and see how it looks in the dataset. </p> 
                            </GetCalculations>
                        </div>
                    </>
                    : null}
                    {step === 4 ?
                    <>
                        <div className='workTitle'>
                            <StepTitle step={step} message='review outcome at varying levels of your metrics'/>   
                        </div>
                        <div className='workItem'>
                            <GetCalculations post={hostname + 'get_multivariate'}
                                            payload={payload}
                                            setState={setCorrelations}
                                            icon={faArrowUpRightDots}
                                            buttonText='view relationships'
                                            type='Correlations'
                                            loadingMessage='loading multivariate statistics'>
                                <p>The next step for us is to look at the potential relationship between each metric and our outcome.</p>
                                <p>How does our outcome look at different values of our metrics?</p>
                            </GetCalculations> 
                        </div>
        
                    </>
                    : null}
                    {step === 5 ?
                    <>
                        <div className='workTitle'>
                            <StepTitle step={step} message='create a model to understand your outcome'/>
                        </div>
                        <div className='workItem'>
                            <GetCalculations post={hostname + 'get_model'} 
                                            payload={payload} 
                                            setState={setModel} 
                                            icon={faChartLine}
                                            buttonText='view causal model'
                                            // instructions='Model Thangs'
                                            type='Model'
                                            loadingMessage='loading model'>
                                <p>We just took a look at how each metric may influence our outcome by themselves. In reality, each metric's impact on the outcome occur at the same time.</p>
                                <p>A model allows us to consider all of our metrics simultaneously, enabling us to see at the relationship between a metric and our outcome removing the influence of the other metrics included in the model.</p>
                                <p>Just like a model train models a train or a doll house models a house, we can develop a statistical model to see how each metric may causally impact our outcome. While we don’t end up with a tiny sofa, we do get two pretty helpful things from a statistical model:</p>
                                    <p>information that tells us how a change in the metric will likely impact the outcome.</p>
                                    <p>a formula that we can use to make predictions about the direction our outcome might go given our metrics - pretty cool!</p>
                            </GetCalculations>
                        </div>

                    </>
                    : null}
                    {step === 6?
                    <>
                        <div className='workTitle'>
                            <StepTitle step={step} message='put your model into action'/>
                        </div>
                        <div className='workItem'>
                            <SetScenario metrics={metrics} 
                                        data={data}
                                        keyMetric={keyMetric}
                                        setKeyMetric={setKeyMetric}
                                        contextualMetrics={contextualMetrics}
                                        setContextualMetrics={setContextualMetrics}/>
                        </div>
                    </>
                    :null}
                    {step === 7?
                    <>
                        <div className='workTitle'>
                            <StepTitle step={step} message='all aboard the data driven express'/>
                        </div>
                        <div className='workItem'>
                            <GetCalculations post={hostname + 'get_scenarios'} 
                                            payload={payload} 
                                            setState={setScenario} 
                                            icon={faBinoculars}
                                            buttonText='view scenario'
                                            metrics={metrics}
                                            setKeyMetric={setKeyMetric}
                                            keyMetric={keyMetric}
                                            setContextualMetrics={setContextualMetrics}
                                            contextualMetrics={contextualMetrics}
                                            contextualMetricCategory={contextualMetricCategory}
                                            // instructions='Scenario Thangs'
                                            type='Scenario'
                                            loadingMessage='loading scenario'>
                                <p>Now it’s time to see your scenario in action.</p>
                                <p>Click the view scenario button to view your very own scenario model.</p>
                                <p>You’ll be able to play around with different scenarios and get ideas not only about what could happen, but also what you can do to have a hand in shaping the future direction of your outcome.</p>
                            </GetCalculations>
                        </div>
                    </>
                    : null}
                    {step > 7?
                    <>
                        <div className='workTitle'>
                            <StepTitle step={step} message='let the journey continue . . .'/>
                        </div>
                        <div className='workItem'>
                            <p>Congratulations!</p>
                            <p>You have taken a journey with your data. But this is just the beginning!</p>
                            <p>As you answer one question, new questions are likely to emerge. This is a cyclical process that we iterate over countless times.</p>
                            <p>I would love your feedback on your experience using this app! Please <a className="emailme" href="mailto:evalutionapp@gmail.com">email me</a> with any thoughts. Thank you so much for your time! :)</p>
                        </div>
                    </>
                    : null}
                    
                    <div className='workNav'>
                    {step !== 0 ?
                        <button className='goBack' 
                                onClick={goBack}
                                title='Go back to the previous step.'>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                            </button> 
                        : null}

                    {step === 0 ? 
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                        : null
                    }
                    {step === 1 ? data ?
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                        : null : null
                    }
                    {step === 2 ? metrics ?
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                        : null : null
                    }
                    {step === 3 ?
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    :
                    null}
                    {step === 4 ?
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    :
                    null}
                    {step === 5 ?
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    :
                    null}
                    {step === 6 ? contextualMetrics ?
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    : null : null
                    }
                    {step === 7 ?
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    :
                    null}
                    {/* {step < 8 ? 
                    OKmoveOn ?
                        <button className='moveOn' 
                                onClick={moveOn} 
                                title='Go on to the next step.'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                        : null
                        : null
                    } */}
                    </div>

                </div>
            </div>
            <div className='rightTool'/>
        </div>
        </>
    )
};

export default Canvas;