import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../CSS/GetCalculations.css';
import ScenarioModeling from './ScenarioModeling';
import DescriptiveViewer from './DescriptiveViewer';
import MovingText from 'react-moving-text';

function GetCalculations(props) {
    const [calc, setCalc] = useState();
    const [progress, setProgress] = useState('');
    
    function getCalculations() {
        setProgress('loading');
        axios.post(props.post, props.payload)
             .then(function (response) {
                    setCalc(response.data);
                    props.setState(response.data);
            })
    };

    return (

        <div className="getcalcgrid">
            <div className="viewresults">
                { calc ?  
                    props.type === "Scenario" ? 
                        <ScenarioModeling 
                            data={calc}
                            requestdata={props.payload.data} 
                            metrics={props.metrics}
                            setKeyMetric={props.setKeyMetric}
                            keyMetric={props.keyMetric}
                            contextualMetrics={props.contextualMetrics}
                            contextualMetricCategory={props.contextualMetricCategory}
                            setContextualMetrics={props.setContextualMetrics}
                            outcome={props.payload.outcome}
                            post={props.post}
                            payload={props.payload}/>
                        : 
                        <DescriptiveViewer data={calc} 
                                           type={props.type} 
                                           requestdata={props.payload.data} 
                                           outcome={props.payload.outcome}/>
                : 
                    <>
                        <>{props.children}</>
                        <br/>
                        <button className='calculationButton' 
                                onClick={() => getCalculations()}>
                            <br/>
                            <FontAwesomeIcon icon={props.icon}/>
                            {/* <p>Tabulate</p> */}
                            <p>{props.buttonText}</p>  
                        </button>
                        {progress === 'loading' ?
                        <>
                            <MovingText
                                    className="loadingMessage"
                                    type="fadeOut"
                                    duration="2000ms"
                                    delay="0s"
                                    direction="normal"
                                    timing="ease"
                                    iteration="infinite"
                                    fillMode="none">
                                <p>{props.loadingMessage + " . . . "}</p>
                            </MovingText>
                        </>
                        :
                        null}  
                    </>}
        
        </div>
    </div>
    );
}

export default GetCalculations;