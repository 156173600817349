import React, { useState } from "react";
import RefactoredBarChart from './RefactoredBarChart';
import RefactoredHistogram from "./RefactoredHistogram";
import PDFDownloader from "./PDFDownloader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import ToolTip from "./ToolTip";

function Univariate(props) {
    const [viewToolTips, setViewToolTips] = useState(false);
    const toggleTipViewStyle = {display: viewToolTips ? '' : 'none'};

    const toggleTipView = () => {
        setViewToolTips(current => !current);
      };

    return (
        <>
            <div className='univariateTitle'>
                {/* <div className='univariateTitleMetric'>
                    Descriptive Statistics for <strong>{props.currentRecord.metric.replace(/_/g, ' ')}</strong>
                </div> */}
                <button className='modelViewToolTips' 
                    onClick={toggleTipView}
                    title='View tips and helpful information'>
                    <FontAwesomeIcon icon={faLightbulb}/>
                </button>
                
                <div className='modelDownloader'>
                    <PDFDownloader 
                        disableToolTips={setViewToolTips}
                        downloadFileName={props.currentRecord.metric + ' Statistics'}
                        rootElementId='univariateDownload'
                    />
                </div>
                {/* <div className='univariateTitleViz'>
                    <p>Vizing out!</p>
                </div> */}
            </div>
            <div id='univariateDownload' className='displayResults'>
            {/* <div className='displayResults'> */}
                <div className='displayResultsMetric1'>
                    <p>
                        <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                            Something we can measure and record. Also a column in our file.
                        </ToolTip>
                        Metric:
                        <br/> 
                        {props.currentRecord.metric.replace(/_/g, ' ')}
                    </p>
                </div>
                <div className='displayResultsMetric2'>
                    {props.currentRecord.type === 'cat' ? 
                    <>
                        
                        <p>
                            <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                                The most common value in your dataset for this metric (a measure of central tendency).
                            </ToolTip>
                            Mode:
                            <br/>
                            {props.currentRecord.mode}
                        </p>
                    </>
                    :
                    <>
                        <p>
                            <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                                The most common value (or average) in your dataset for this metric (a measure of central tendency).
                            </ToolTip>
                            Mean:
                            <br/> 
                            {parseFloat(props.currentRecord.mean).toLocaleString("en-US", {maximumFractionDigits: 2})}
                        </p>
                    </>

                    }
                </div>
                <div className='displayResultsMetric3'>
                    {props.currentRecord.type === 'cat' ? 
                        <p>
                            <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                                The percentage of rows that have the most frequent value in your dataset.
                            </ToolTip>
                            Mode Percent:
                            <br/> {
                            parseFloat(props.currentRecord.modePercetage * 100).toFixed(0)}%
                        </p>
                    :
                    <>
                        <p>
                            <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                                The smallest value of this metric in your dataset.
                            </ToolTip>
                            Minimum: 
                            {parseFloat(props.currentRecord.min).toLocaleString("en-US", {maximumFractionDigits: 2})}
                        </p>
                        <p>
                            <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                                The largest value of this metric in your dataset.
                            </ToolTip>
                            Maximum: 
                            {parseFloat(props.currentRecord.max).toLocaleString("en-US", {maximumFractionDigits: 2})}
                        </p>
                    </>

                    }
                </div>
                <div className='displayResultsViz'>
                    {props.currentRecord.type === 'cat' ? 
                        <RefactoredBarChart
                            title={'Distribution of ' + props.currentRecord.metric.replace(/_/g, ' ')}
                            tooltip='The percentage of records that are a given value for your metric. (a measure of dispersion or spread)'
                            toggleTipViewStyle={toggleTipViewStyle}
                            data={JSON.parse(props.currentRecord.counts)} 
                            metric={props.currentRecord.metric} amount='Count' 
                            total={props.currentRecord.totalRecords}/>
                        : 
                        <RefactoredHistogram 
                            title={'Distribution of ' + props.currentRecord.metric.replace(/_/g, ' ')}
                            tooltip='The percentage of records that are a given value for your metric. (a measure of dispersion or spread)'
                            toggleTipViewStyle={toggleTipViewStyle}
                            data={JSON.parse(props.currentRecord.histogram)} 
                            metric={props.currentRecord.metric}/>
                        }
                </div>
            </div>
        </>
    )
};

export default Univariate;