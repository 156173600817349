import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'


const ScenarioModelingUpdate = (props) => {
    const [page, setPage] = useState(1);

    if (props.contextualMetrics === 'Default') {
        props.setContextualMetrics(props.data.ContextualMetrics);
      }

    if (props.keyMetric === 'Default') {
        props.setKeyMetric(props.data.KeyMetric);
      }

    const contextMetrics = props.contextualMetrics;

    var contextMetric1 = Object.keys(contextMetrics)[page-1];
    var contextMetric2 = Object.keys(contextMetrics)[page];
    var contextMetric3 = Object.keys(contextMetrics)[page+1];

    var numberOfMetrics = Object.keys(props.data.ContextualMetrics).length;

    const goForwardMetrics = () => {setPage(page+1)};
    const goBackMetrics = () => {setPage(page-1)};

    return (
        <>
            <div className='scenarioModelingKeyMetricTitle'>
                Key Metric:
            </div>
                <div className='scenarioModelingKeyMetric'>
                    {props.keyMetric}
                </div>
            { Object.keys(props.contextualMetrics).length > 0 ?
            <div className='scenarioModelingContextualMetricTitle'>
                Contextual Metrics:
            </div>
            : null
            }
                <div className='scenarioModelingContextualMetric1'>
                    {contextMetric1}
                </div>
                <div className='scenarioModelingContextualMetric1Value'>
                    {contextMetric1 ?
                    <>
                    {contextMetrics[contextMetric1]}
                    </>
                    :
                    null}
                </div>
                <div className='scenarioModelingContextualMetric2'>
                    {contextMetric2}
                </div>
                <div className='scenarioModelingContextualMetric2Value'>
                    {contextMetric2 ?
                    <>
                    {contextMetrics[contextMetric2]}
                    </>
                    :
                    null}
                </div>
                <div className='scenarioModelingContextualMetric3'>
                    {contextMetric3}
                </div>
                <div className='scenarioModelingContextualMetric3Value'>
                    {contextMetric3 ?
                    <>
                        {contextMetrics[contextMetric3]}
                    </>
                    :
                    null}
                </div>
            <div className='scenarioModelingContexutalMetricMenu'>
                {page > 1 ?
                    <button
                        className='goBack'
                        onClick={goBackMetrics}
                    >
                        <FontAwesomeIcon icon={faArrowLeft}/>
                       
                    </button>
                :
                    null
                }
                {page + 2 < numberOfMetrics ?
                    <button
                        className='moveOn'
                        onClick={goForwardMetrics}
                    >
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
                : null}
            </div>
        </>
  )
}

export default ScenarioModelingUpdate