import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function ToolTip(props) {
    return ( 
        <Tippy content={props.children}>
            <span style={props.toggleTipViewStyle}>
                <FontAwesomeIcon className='helpIcon' icon={faLightbulb}/>
                &nbsp;
            </span>
        </Tippy>
     );
}

export default ToolTip;