import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom";
import '../CSS/Header.css';
function Header() {
  // const navigate = useNavigate();
  return (
    <header className="header">
      <NavLink to="/">
        <FontAwesomeIcon className="logo" icon={faChartLine} />
      </NavLink>

          <br/>
          e v a l u t i o n
    </header>
  )
}

export default Header
