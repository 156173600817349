import React, { useState } from 'react'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faBinoculars } from '@fortawesome/free-solid-svg-icons'
import MovingText from 'react-moving-text';
import axios from 'axios';

const average = array => array.reduce((a, b) => a + b) / array.length;

const style = (theme) => ({
    ...theme,
    borderRadius: 8,
    control: (base) => ({
        ...base,
        fontFamily: "Times New Roman",
        fontSize: "20px"
      }),
    colors: {
        ...theme.colors,
        neutral90: '#7C6674',
        primary25: '#e0dade',
        primary: '#7C6674',
        neutral10:'#e0dade',
        neutral80: '#7C6674',
        danger:'#e0dade',
        dangerLight: '#7C6674',
    },
  });

const ScenarioModelingUpdate = (props) => {
    const [currentStatus, setCurrentStatus] = useState('Initial Load');
    const [page, setPage] = useState(1);

    if (props.contextualMetrics === 'Default') {
        props.setContextualMetrics(props.data.ContextualMetrics);
      }

    if (props.keyMetric === 'Default') {
        props.setKeyMetric(props.data.KeyMetric);
      }

    const saveKeyMetric = (event) => {
        const selectedKeyMetric = event.value;
        const nonKeyMetrics = new Set(props.metrics);
    
        nonKeyMetrics.delete(selectedKeyMetric);
    
        props.setContextualMetrics(currentContextMetrics => {
            delete currentContextMetrics[selectedKeyMetric];
            if (typeof [...new Set(props.requestdata.map(categories => categories[props.keyMetric]))][0] === 'string')
            {currentContextMetrics[props.keyMetric] = [...new Set(props.requestdata.map(categories => categories[props.keyMetric]))].sort()[0]}
            else
            {currentContextMetrics[props.keyMetric] = parseFloat(average(props.requestdata.map(categories => categories[props.keyMetric]).filter(function( element ) {return element !== undefined;})).toFixed(2));}
            return currentContextMetrics;
        });
        
        props.setKeyMetric(selectedKeyMetric);
        setCurrentStatus('Unsubmitted Changes');
    }

    const contextMetrics = props.contextualMetrics;

    var contextMetric1 = Object.keys(contextMetrics)[page-1];
    var contextMetric2 = Object.keys(contextMetrics)[page];
    var contextMetric3 = Object.keys(contextMetrics)[page+1];

    const saveContextCategoryCat = (event, metric) => {
        props.setContextualMetrics({
            ...props.contextualMetrics,
            [metric]: event.value
        });
        setCurrentStatus('Unsubmitted Changes');
    }

    const saveContextCategoryNum = (event, metric) => {
        props.setContextualMetrics({
            ...props.contextualMetrics,
            [metric]: event.target.valueAsNumber
        });
        setCurrentStatus('Unsubmitted Changes');
    }

    var numberOfMetrics = Object.keys(props.data.ContextualMetrics).length;

    const goForwardMetrics = () => {setPage(page+1)};
    const goBackMetrics = () => {setPage(page-1)};

    function updateScenario() {
        setCurrentStatus('Submitting Changes');
        var payload = props.payload;
        payload['keyMetric'] = props.keyMetric;
        payload['contextualMetrics'] = contextMetrics;
        axios.post(props.post, payload)
             .then(function (response) {
                    props.setData(response.data);
                    setCurrentStatus('Scenario Updated');
            })
    };

    return (
        <>
            <div className='scenarioModelingKeyMetricTitle'>
                Key Metric:
            </div>
                <div className='scenarioModelingKeyMetric'>
                    <Select
                            isSearchable
                            options={props.metrics.sort().map(d => ({'value': d, 'label':d}))}
                            onChange={saveKeyMetric}
                            theme={style}
                            value={{'value': props.keyMetric, 'label': props.keyMetric}}
                        />
                </div>
            <div className='scenarioModelingContextualMetricTitle'>
                Contextual Metrics:
            </div>
                <div className='scenarioModelingContextualMetric1'>
                    {contextMetric1.replace(/_/g, ' ')}
                </div>
                <div className='scenarioModelingContextualMetric1Value'>
                    {contextMetric1 ?
                    <>
                    {typeof contextMetrics[contextMetric1] === 'string' ?
                        <Select
                            isSearchable
                            options={[...new Set(props.requestdata.map(categories => categories[contextMetric1]))].sort().map(d => ({'value': d, 'label':d}))}
                            value={{'value':contextMetrics[contextMetric1],'label':contextMetrics[contextMetric1]}}
                            theme={style}
                            onChange={event => saveContextCategoryCat(event, contextMetric1)}
                        />
                    :
                        <input
                            className='setNumContextMetric'
                            type='number' 
                            step='any'
                            min={Math.min(props.requestdata.map(categories => categories[contextMetric1]).sort()).toFixed(2)}
                            max={Math.max(...[...new Set(props.requestdata.map(categories => categories[contextMetric1]))].sort()).toFixed(2)}
                            value={contextMetrics[contextMetric1]}
                            onChange={event => saveContextCategoryNum(event, contextMetric1)}
                        />
                    }
                    </>
                    :
                    null}
                </div>
                <div className='scenarioModelingContextualMetric2'>
                    {contextMetric2.replace(/_/g, ' ')}
                </div>
                <div className='scenarioModelingContextualMetric2Value'>
                    {contextMetric2 ?
                    <>
                    {typeof contextMetrics[contextMetric2] === 'string' 
                    ?
                        <Select
                            isSearchable
                            options={[...new Set(props.requestdata.map(categories => categories[contextMetric2]))].sort().map(d => ({'value': d, 'label':d}))}
                            value={{'value':contextMetrics[contextMetric2],'label':contextMetrics[contextMetric2]}}
                            theme={style}
                            onChange={event => saveContextCategoryCat(event, contextMetric2)}
                        />
                    :
                        <input
                            className='setNumContextMetric'
                            type='number' 
                            step='any'
                            min={Math.min(props.requestdata.map(categories => categories[contextMetric2]).sort()).toFixed(2)}
                            max={Math.max(...[...new Set(props.requestdata.map(categories => categories[contextMetric2]))].sort()).toFixed(2)}
                            value={contextMetrics[contextMetric2]}
                            onChange={event => saveContextCategoryNum(event, contextMetric2)}
                        />
                    }
                    </>
                    :
                    null}
                </div>
                <div className='scenarioModelingContextualMetric3'>
                    {contextMetric3.replace(/_/g, ' ')}
                </div>
                <div className='scenarioModelingContextualMetric3Value'>
                    {contextMetric3 ?
                    <>
                    {typeof contextMetrics[contextMetric3] === 'string' 
                        ?
                            <Select
                                isSearchable
                                options={[...new Set(props.requestdata.map(categories => categories[contextMetric3]))].sort().map(d => ({'value': d, 'label':d}))}
                                value={{'value':contextMetrics[contextMetric3],'label':contextMetrics[contextMetric3]}}
                                theme={style}
                                onChange={event => saveContextCategoryCat(event, contextMetric3)}
                            />
                        :
                            <input
                                className='setNumContextMetric'
                                type='number' 
                                step='any'
                                min={Math.min(props.requestdata.map(categories => categories[contextMetric3]).sort()).toFixed(2)}
                                max={Math.max(...[...new Set(props.requestdata.map(categories => categories[contextMetric3]))].sort()).toFixed(2)}
                                value={contextMetrics[contextMetric3]}
                                onChange={event => saveContextCategoryNum(event, contextMetric3)}
                            />
                        }
                        </>
                        :
                        null}
                </div>
            <div className='scenarioModelingContexutalMetricMenu'>
                {currentStatus === 'Unsubmitted Changes' ?
                    <>
                    <button
                        className='updateScenario'
                        onClick={updateScenario}
                    >
                        <span>&nbsp;&nbsp;&nbsp;Update </span>
                        <FontAwesomeIcon icon={faBinoculars}/>
                        <span> Scenario</span>
                    </button>
                    <br/>
                    </>
                :
                    null
                }
                {currentStatus === 'Submitting Changes' ?
                <MovingText
                    type="fadeOut"
                    duration="2000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="infinite"
                    fillMode="none"
                >
                    Updating Scenario . . .
                </MovingText>
                :
                    null
                }
                {currentStatus === 'Scenario Updated' ?
                <>Scenario Updated!<br/></>
                :
                null}
                {page > 1 ?
                    <button
                        className='goBack'
                        onClick={goBackMetrics}
                    >
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </button>
                :
                    null
                }
                {page + 2 < numberOfMetrics ?
                    <button
                        className='moveOn'
                        onClick={goForwardMetrics}
                    >
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
                :
                    null
                }
            </div>
        </>
  )
}

export default ScenarioModelingUpdate