import React, { useState } from 'react';
import { scaleLinear, bin, max, min, format} from 'd3';
import '../CSS/BarChart.css';
import ToolTip from './ToolTip';

const width = 650;
const height = 500;
let margin = {
    top: 20,
    right: 40,
    bottom: 70,
    left: 200,
    };
const innerHeight = height - margin.top - margin.bottom;
const innerWidth = width - margin.left - margin.right;
const xAxisLabelOffset = 40;

const fadeOpacity = .2;

function RefactoredHistogram(props) {
    const [hoveredValue, setHoveredValue] = useState(null);

    const x = scaleLinear()
                .domain([min(props.data), max(props.data)])
                .range([0, innerWidth]);
                // .nice();

    const histogram = bin()
                        .value((d) => d)
                        .domain(x.domain())
                        .thresholds(x.ticks(12));

    const bins = histogram(props.data);

    const y = scaleLinear()
                .domain([0, max(bins, (d) => d.length)])
                .range([innerHeight, 0]);

    const digits = Math.max(...(y.domain().map(item => item.toString().length)));
    const yAxisLabelOffset = -(25+(digits*10));
    const filteredBins = bins.filter((d) => hoveredValue === d.x1);
    const xAxisTickFormat = format(',');
    const labelFormat = format('.0%');
    return (
        <div className="svg-container">
            <br/>
            {props.tooltip ?             
            <ToolTip toggleTipViewStyle={props.toggleTipViewStyle}>
                {props.tooltip}
            </ToolTip>
            :
            null }
            <span className='viz-title'>{props.title}</span>
        <svg width={width} height={height}  version="1.1" viewBox="0 0 650 500"  preserveAspectRatio="xMinYMin meet"  className="svg-content">
            <g transform={`translate(${margin.left},${margin.top})`}>
            // X-Axis
            { x.ticks(4).map(tickValue => (
                <g  className='tick'
                    key={tickValue}
                >
                    <text   
                        key={tickValue}
                        x = {x(tickValue)}
                        y = {innerHeight + 3}
                        dy = ".71em"
                    >
                        {xAxisTickFormat(tickValue)}
                    </text>
                </g>
            ))}
            // Y-Axis
            { y.ticks(4).map(tickValue =>
                <g  className='tick'
                    key={tickValue}
                    transform={`translate(0,${y(tickValue)})`}
                >
                    <text
                        style={{ textAnchor: 'end' }}
                        x={-7.5}
                        dy=".32em"
                    >
                        {xAxisTickFormat(tickValue)}
                    </text>
                    <line x2={innerWidth}/>
                </g>
            )}
            // X-Axis Label
            <text   
                className='axis-label'
                x={innerWidth / 2}
                textAnchor='middle'
                y={innerHeight + xAxisLabelOffset}
            >
                {props.metric.replace(/_/g, ' ')}
            </text>
            // Y-Axis Label
            <text
                className="title"
                textAnchor="middle"
                transform={`translate(${yAxisLabelOffset},${innerHeight/2}) rotate(-90)`}
            >
                Number of Records
            </text>
            // All Bins
            {bins.map((d) => (
            <rect
                opacity={hoveredValue ? fadeOpacity : 1}
                key={d.x0}
                className = 'bar'
                x={1}
                y={y(d.Count)}
                height={innerHeight - y(d.length)}
                width={x(d.x1) - x(d.x0)-1}
                transform={`translate(${x(d.x0)},${y(d.length)})`}
                onMouseEnter={() => {setHoveredValue(d.x1);}}
                onMouseOut={() => {setHoveredValue(null);}}          
            />
            ))}
            // Selected Bin
            {filteredBins.map((d) => (
                <g key={d.x0}>
                    <rect 
                        className = 'bar'
                        x={1}
                        y={y(d.Count)}
                        height={innerHeight - y(d.length)}
                        width={x(d.x1) - x(d.x0) -1}
                        transform={`translate(${x(d.x0)},${y(d.length)})`} 
                        onMouseEnter={() => {setHoveredValue(d.x1);}}
                        onMouseOut={() => {setHoveredValue(null);}}         
                    />
                    <text 
                        className='barHoverLabel'
                        x={((x(d.x0) + x(d.x1))/2)+ 3}
                        y={y(d.length)- 10}
                        dy=".32em"
                        textAnchor='middle'
                        onMouseEnter={() => {setHoveredValue(d.x1);}}
                        onMouseOut={() => {setHoveredValue(null);}}
                    >
                        <tspan x={((x(d.x0) + x(d.x1))/2)+ 3} y={y(d.length)- 10}>{labelFormat(d.length/props.data.length)}</tspan>
                        {/* <tspan x={((x(d.x0) + x(d.x1))/2)+ 3} y={y(d.length)- 20}>{xAxisTickFormat(d.x0)} - {xAxisTickFormat(d.x1)}</tspan> */}
                        
                        
                    </text>
                </g>))}
            </g>
        </svg>
        </div>
    );
}

export default RefactoredHistogram;