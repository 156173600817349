import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';

function Square(props) {
  const [detail, setDetail] = useState(false);
  const navigate = useNavigate();
  
  function showDetail(show) {
    return (
      setDetail(show)
    )
  };

  return (
    <div  className={props.className}
          onClick={() => navigate(props.link)}
          onMouseOver={() => showDetail(true)}
          onMouseLeave={() => showDetail(false)}> 
      {detail ? <h1>{props.description}</h1> : <h1>{props.heading1} <br/> + <br/> {props.heading2}</h1>}
    </div>
  )
}

export default Square
