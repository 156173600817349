import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowUp, faCircleArrowDown, faLightbulb } from '@fortawesome/free-solid-svg-icons'
import '../CSS/ModelViewer.css';
import ToolTip from "./ToolTip";
import PDFDownloader from "./PDFDownloader";

function ModelViewer(props) {
    const sig = parseFloat(props.calc.pvalue * 100).toFixed(0);
    const direction = props.calc.param >= 0 ? 'increase' : 'decrease';
    const avgDiff = props.calc.param >= 0 ? 'higher' : 'lower';

    const [viewToolTips, setViewToolTips] = useState(false);
    const toggleTipViewStyle = {display: viewToolTips ? '' : 'none'};

    const toggleTipView = () => {
        setViewToolTips(current => !current);
      };

    return (
        <>
            <div className='modelViewToolTips'>
                {/* <div> */}
            <button className='downloadButton' 
                    onClick={toggleTipView}
                    title='View tips and helpful information'>
                    <FontAwesomeIcon icon={faLightbulb}/>
            </button>
            </div>

            <div className='modelDownloader'>
                    <PDFDownloader 
                        downloadFileName={props.calc.metric + ' x ' + props.outcome + ' Model Results'}
                        rootElementId='modelDownload'
                        // scale={3}
                    />
                </div>
            <div id='modelDownload' className='model'>
            <div className='metricTitleTitle modelTitle'>
                <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                    <p>Something we can measure and record. Also a column in our file.</p>
                </ToolTip>
                Metric
            </div>
            <div className='directionTitle modelTitle'>
                <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                    <p>If we increase the metric, do we expect the outcome to increase or decrease as a result?</p>
                    <p>Does eating an additional scoop of ice cream increase or decrease one's need for an afternoon nap?</p>
                </ToolTip>
                Direction
            </div>
            <div className='interpreationTitle modelTitle'>
                <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                    <p>Each coefficient has a statistical interpretation that represents how a modification to the given metric will impact the outcome.</p>
                    <p>For every 20 pretzels I eat, I’m going to need one additional glass of water.</p>
                </ToolTip>
                Interpretation
            </div>
            <div className='significanceTitle modelTitle'>
                <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                    <p>The likelihood that this casual relationship is random and we would not expect to see it in reality.</p>
                    <p>Also can serve as a measure of our confidence that the relationship really exists (smaller values mean we are more confident).</p>
                </ToolTip>
                P-Value
            </div>
            <div className='modelScoreTitle modelTitle'>
                <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                    <p>Like us, machines are doing the best they can. It’s not perfect (nor provides a 100% true picture of the phenomena it is modeling). We can provide our model with different scores to help us determine the strengths, weaknesses, and appropriate applications of our model. The score care we use looks differently depending on the nature of our outcome.</p>
                </ToolTip>
                Score
            </div>
            <div className='metric'>
                {props.calc.metric.replace(/_/g, ' ')}
            </div>
            <div className='direction'>
                <FontAwesomeIcon className='directionIcon'
                                 icon={direction === 'increase' ? faCircleArrowUp : faCircleArrowDown}
                />
            </div>
            <div className='interpretation'>
                {props.calc.modelType === 'ols' ? 
                    props.calc.type === 'cat'? 
                        <>
                        {props.calc.metric.slice(0,24) + (props.calc.metric.length > 24 ? "..." : "")} is associated with a {parseFloat(props.calc.param).toLocaleString("en-US", {maximumFractionDigits: 2})} {avgDiff} {props.outcome} average then {props.calc.Omitted} (the most common category for {props.calc.Column}).
                        </>
                    : 
                        <>
                        A one unit increase in {props.calc.metric} is associated with a {parseFloat(props.calc.param).toLocaleString("en-US", {maximumFractionDigits: 2})} {direction} in {props.outcome}.
                        </>
                :
                    props.calc.type === 'cat'? 
                        <>
                        {props.calc.metric.slice(0,24) + (props.calc.metric.length > 24 ? "..." : "")} have {props.calc.oddsChange * 100 < .001 ? 'less than .001' : parseFloat(props.calc.oddsChange * 100).toLocaleString("en-US", {maximumFractionDigits: 2})}% {direction === 'increase' ? 'more ' : 'less '} odds of a {props.outcome} outcome than {props.calc.Omitted} (the most common category for {props.calc.Column}).
                        </>
                    :
                        <>
                        A one unit increase in {props.calc.metric.replace(/_/g, ' ')} is associated with {direction === 'increase' ? 'an ' : 'a ' }{direction} of {props.calc.oddsChange * 100 < .001 ? 'less than .001' : parseFloat(props.calc.oddsChange * 100).toLocaleString("en-US", {maximumFractionDigits: 2})}% in the odds of a {props.outcome} outcome.
                        </>}
            </div>
            <div className='significance'>
                {sig < 0.01 ? 
                <>
                There is less than a .01% chance this relationship is random.
                </>
                :
                <>
                There is a {sig}% chance this relationship is random.
                </>}
            </div>
            <div className='modelScore'>
            {props.calc.modelType === 'ols' ? 
                <p>RMSE: {JSON.parse(props.calc.modelScore).RMSE.toFixed(2)}</p>
                :
                <>
                    <p>
                        <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                            <p>The percentage of times the model made a correct prediction.</p>
                        </ToolTip>
                        Accuracy: {parseFloat(JSON.parse(props.calc.modelScore).ACCURACY * 100).toFixed(2)}%
                    </p>
                    <p>
                        <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                            <p>The proportion of model identified {props.outcome}s that were actually {props.outcome}s.</p>
                        </ToolTip>
                        Precision: {parseFloat(JSON.parse(props.calc.modelScore).PRECISION * 100).toFixed(2)}%
                    </p>
                    <p>
                        <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                            <p>The percentage of {props.outcome}s that the model identified out of all actual {props.outcome}s.</p>
                        </ToolTip>
                        Recall: {parseFloat(JSON.parse(props.calc.modelScore).RECALL * 100).toFixed(2)}%
                    </p>

                </>
            }
            </div>
        </div>
        </>
    );
}
export default ModelViewer;