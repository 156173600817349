import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTableList, faDolly, faBoxesStacked } from '@fortawesome/free-solid-svg-icons'
import MovingText from 'react-moving-text';
import '../CSS/GetColumns.css'

function GetColumns(props) {
    const [instructions, setInstructions] = useState('Please select "Get Columns" below to load the columns in your uploaded file. We will start by selecting the column in your file that you would like to understand better, or predict.');
    const [progress, setProgress] = useState('');
    const [columns, setColumns] = useState();
    const [outcome, setOutcome] = useState();
    const [metrics, setMetrics] = useState();
    const [positiveClass, setPositiveClass] = useState();
    const [metricOptions, setMetricOptions] = useState();

    const style = (theme) => ({
        ...theme,
        borderRadius: 8,
        spacing: {
            baseUnit: 10,
            controlHeight: 40,
            menuGutter: 20,
        },
        colors: {
            ...theme.colors,
            neutral90: '#7C6674',
            primary25: '#e0dade',
            primary: '#7C6674',
            neutral10:'#e0dade',
            neutral80: '#7C6674',
            danger:'#e0dade',
            dangerLight: '#7C6674',
        },
      });
    const getColumns = () => {
        setProgress('loading columns . . . ');
        // axios.post('http://127.0.0.1:8000/get_columns', props.data)
        axios.post(props.hostname + 'get_columns', props.data)
        .then(function (response) {
        props.setColumns(response.data);
        setColumns(response.data);
        setMetricOptions(columns);
        setProgress('outcome');
        })
    };

    const saveOutcome = (event) => {
        props.setOutcome(event.value);
        setOutcome(event.value);
        setMetricOptions(columns.filter(x => x.value !== event.value));
        if (typeof [...new Set(props.data.map(categories => categories[event.value]))][0] === 'string')
        {setProgress('positiveClass');}
        else
        {setProgress('metrics');}
    }

    const savePositiveClass = (event) => {
        props.setPositiveClass(event.value);
        setPositiveClass(event.value);
        setProgress('metrics');
    }
    const saveMetrics = event => {
        const values = Array.from(event, option => option.value);
        props.setMetrics(values);
        setMetrics(values);
    }

    const useAll = () => {
        const allMetrics = [...new Set(metricOptions.map(d => d['label']))];
        setProgress('UseAll');
        props.setMetrics(allMetrics);
        setMetrics(allMetrics);


    }
    const defaultOutcome = (props.outcome) ? {'value': props.outcome, 'label':props.outcome} : null;
    const defaultMetrics = (props.metrics) ? props.metrics.sort().map(d => ({'value': d, 'label':d})) : null;
    return (
        <>
        {/* <p>{progress}</p> */}
        <div className='getcolumngrid'>
            <div className='setoutcome'>
            { columns ? 
            <> 
                {progress === 'outcome' ? 
                    <>
                        <p>The first thing you will select is the column that contains your “outcome”.</p> 
                        <p>This is the column that you care the most about, and it is an indication of how things are going.</p>
                        <p>This is the column, more than any other, that you want to impact, improve, or "move the needle" on.</p>
                        <label> outcome: </label>
                        <Select options={columns}
                                onChange={saveOutcome}
                                theme={style}
                                defaultValue = {defaultOutcome}
                                placeholder = 'select your outcome'      
                        /> 
                        </> 
                        : 
                        null}

                        {progress === 'positiveClass' ? 
                        <> 
                            <p>It looks like your outcome contains more than one category.</p> 
                            <p>Please select the category that you would like to predict as your outcome.</p>
                            
                            <label> prediction category: </label>
                            <Select options={[...new Set(props.data.map(categories => categories[outcome]))].sort().map(d => ({'value': d, 'label':d}))}
                                    onChange={savePositiveClass}
                                    theme={style}
                                    defaultInvalueputValue={positiveClass}
                            />
                        </> 
                        : 
                        null}
                    
                    
                    {progress === 'metrics' ? 
                    
                    <>
                        <p>Great, now we know that <strong className='changeOutcome' onClick={() => setProgress('outcome')}>{outcome}</strong> is your outcome variable.</p> 
                        <p> Now just because we care about something doesn’t mean we have direct control over it. Even if we have some say in the matter, there are usually other factors that impact our outcome.</p> 
                        <p>You most likely have some of these other factors hiding in your columns! These “metrics” may provide pathways for us to adjust our outcome.
                        <br/>
                        <br/>
</p>
                        {/* <p> Would you like to select your metrics from your file's columns, or use all non-outcome columns as metrics? </p> */}
                        <div className='scenarioOptions'>
                            <div className='scenarioOption1'>
                                <button className='calculationButton' 
                                        onClick={() => setProgress('SelectColumns')}
                                        >
                                    <br/>
                                    <FontAwesomeIcon icon={faDolly}/>
                                    <p>select columns</p>
                                </button>
                            </div>
                            <div className='scenarioOption2'>
                                <button className='calculationButton' 
                                        // onClick={() => setProgress('UseAll')}
                                        onClick={useAll}
                                        >
                                    <br/>
                                    <FontAwesomeIcon icon={faBoxesStacked}/>
                                    <p>use all columns</p>
                                </button>
                            </div>
                        </div>
                    </> 
                    :
                    null
                    }
            </>
            : 
            <>
             {props.metrics ?
                            <>
                            <p>To select different columns, click "see columns".</p> 
                            <p> Click the forward arrow to continue with your current selection.</p>
                            </>
                            :
                            null}
            <div className='center'>
                    <button className='uploadFile' 
                            onClick={() => getColumns()}>
                        <br/>
                        <FontAwesomeIcon icon={faTableList}/>
                        <p>see columns</p>
                    </button>
            </div> 
            {progress === 'loading columns . . . ' ?
            <>
            <br/>        
            <MovingText
                    type="fadeOut"
                    duration="2000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="infinite"
                    fillMode="none">
                <p>{progress}</p>
            </MovingText>
            </>
            :
            null}
            </>}

            {progress === 'SelectColumns' ? 
                <div className='setmetric'>
                    <p>Select specific metrics from your file. Include whatever columns are of interest to you.</p>
                    <label> metrics: </label>
                    <Select isMulti
                            isSearchable
                            options={metricOptions}
                            onChange={saveMetrics}
                            theme={style}
                            defaultValue = {defaultMetrics}
                            placeholder = 'select your metrics'
                    />
                    <p className='changeOutcome' onClick={useAll}>use all metrics</p>
                    <br/>
                    <p>One you have selected your metrics, please click the forward arrow to take an up close look at your data!</p>
                </div>
            : 
            null}

            {progress === 'UseAll' ?
            <>
                <p>Great! We will include all of columns as metrics with the exception of your selected outcome <strong className='changeOutcome' onClick={() => setProgress('outcome')}>{props.outcome}</strong>!</p>
                <p>Some columns may end up being excluded if they do not meet certain data quality standards.</p>
                <p>Please click the forward arrow to take an up close look at your data!</p>
                {/* <p>{JSON.stringify(metricOptions)}</p> */}
                {/* <p>{JSON.stringify([...new Set(metricOptions.map(d => d['label']))])}</p> */}
                {/* <p>{JSON.stringify(props.metrics)}</p> */}
            </>
            :
            null}
            </div>
        </div>
    </>
    )
}

export default GetColumns