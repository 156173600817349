import React, { useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonDigging, faPersonWalkingLuggage } from '@fortawesome/free-solid-svg-icons'
import Pagination from './Pagination';
import GoTo from './GoTo';
import '../CSS/ScenarioModeling.css'

function SetScenario(props) {
    const [showOptions, setShowOptions] = useState();
    const [viewMode, setViewMode] = useState('Key');
    const [index, setIndex] = useState(1);
    const [recordsPerPage] = useState(1);
    const indexOfLastRecord = index * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    var contextMetricList = (viewMode === 'Context') ? Object.keys(props.contextualMetrics) : null;
    var item = (viewMode === 'Context') ? contextMetricList.slice(indexOfFirstRecord, indexOfLastRecord)[0] : null;
    var nPages = (viewMode === 'Context') ? Math.ceil(contextMetricList.length / recordsPerPage) : null;
    // const contextMetricList = Object.keys(props.contextualMetrics);
    // const item = contextMetricList.slice(indexOfFirstRecord, indexOfLastRecord)[0];
    // const nPages = Math.ceil(contextMetricList.length / recordsPerPage);
    
    const average = array => array.reduce((a, b) => a + b) / array.length;

    const metrics = props.metrics;

    // const index = 0;
    // const item = Object.keys(props.contextualMetrics)[index];
    // console.log(Object.keys(props.contextualMetrics));
    // console.log(item);
    

    // const contextDict = props.metrics.reduce((dictionary, categoryOptions) => {
    //     dictionary[categoryOptions] = 'Default';
    //     return dictionary;
    //   }, {});

    //   const [contextMetrics, setContextMetrics] = useState(contextDict);

    if (metrics.length === 1) {
        props.setKeyMetric('Default');
        props.setContextualMetrics('Default');
    }

    // let categoryOptions = [...new Set(props.data.map(item => item[props.contextualMetrics]))];

    const setOwnScenario = () => {
        setShowOptions('Yes');
    }

    const useDefaultScenario = () => {
        setShowOptions('No');
        props.setKeyMetric('Default');
        props.setContextualMetrics('Default');
    }

    const saveKeyMetric = (event) => {
        props.setKeyMetric(event.value);
        // props.setContextualMetric(metrics.filter(x => x !== event.value));

        props.setContextualMetrics(
            metrics.filter(x => x !== event.value).reduce((dictionary, contextMetric) => {
                (typeof [...new Set(props.data.map(categories => categories[contextMetric]))][0] === 'string')
                ?
                dictionary[contextMetric] = [...new Set(props.data.map(categories => categories[contextMetric]))].sort()[0]
                :
                dictionary[contextMetric] = parseFloat(average(props.data.map(categories => categories[contextMetric]).filter(function( element ) {return element !== undefined;})).toFixed(2));
                return dictionary;
              }, {})
        );

        setViewMode('Context');
    }

    const saveContextCategoryCat = (event, metric) => {
        props.setContextualMetrics({
            ...props.contextualMetrics,
            [metric]: event.value
        });
        console.log()
    }

    const saveContextCategoryNum = (event, metric) => {
        props.setContextualMetrics({
            ...props.contextualMetrics,
            [metric]: event.target.valueAsNumber
        });
    }

    const moveToKey = () => {
        setViewMode('Key');
    }
    const style = (theme) => ({
        ...theme,
        borderRadius: 8,
        spacing: {
            baseUnit: 10,
            controlHeight: 40,
            menuGutter: 20,
        },
        colors: {
            ...theme.colors,
            neutral90: '#7C6674',
            primary25: '#e0dade',
            primary: '#7C6674',
            neutral10:'#e0dade',
            neutral80: '#7C6674',
            danger:'#e0dade',
            dangerLight: '#7C6674',
        },
      });

    return(
        <>
            {showOptions ?
            <>
                {showOptions === 'Yes' ?
                <>
                {viewMode === 'Context' ?
                <>
                {/* <p>Great! You have selected <strong>{props.keyMetric}</strong> as your key metric.</p> */}
                <p>Great! You have selected 
                    <strong className='changeOutcome' onClick={moveToKey}> {props.keyMetric} </strong>
                    {/* <button onClick={moveToKey}
                            className='changeKeyMetric' 
                            title="Click to change your key metric.">
                        <strong> {props.keyMetric} </strong>
                    </button>  */}
                as your key metric.</p>
                <p>In the following screens you will be able to set a fixed value for the rest of your non-key metrics. We will call these <strong>contextual metrics</strong>, because they contribute to the context of your scenario. They help paint the picture of the scenario you are using.</p>
                <p>You can move through the screens to set your desired values for each contextual metric. You can also skip this step to use default values for each metric (the default is the mean for numeric metrics, and the first category for categorical metrics). You will be able to make changes in the subsequent page as well.</p>

                {/* <button 
                    onClick={moveToKey}
                    className='changeKeyMetric'
                >
                    Change Key Metric
                </button> */}
                <br/>
                
                {/* {item}
                {props.contextualMetrics[item]}
                {JSON.stringify(props.contextualMetrics)} */}
                { 
                
                // Object.keys(props.contextualMetrics).map((item, index) => (

                // <>
                // {
                    typeof [...new Set(props.data.map(categories => categories[item]))][0] === 'string' ?
                    <div className='setContextMetric'>
                    <p>Please select the category you would like to use for your contextual metric <strong>{item}</strong>.</p>
                    <Select
                        isSearchable
                        options={[...new Set(props.data.map(categories => categories[item]))].sort().map(d => ({'value': d, 'label':d}))}
                        onChange={event => saveContextCategoryCat(event, item)}
                        value={{'value': props.contextualMetrics[item], 'label':props.contextualMetrics[item]}}
                        // defaultValue={{'value':[...new Set(props.data.map(categories => categories[item]))].sort()[0], 'label':[...new Set(props.data.map(categories => categories[item]))].sort()[0]}}
                        theme={style}
                    />
                    {/* <br/> */}
                    </div>
                    :
                    <div className='setContextMetric'>
                        {/* {index+1}. */}
                        <p>Please select the value you would like to set for your contextual metric <strong>{item}</strong>.</p>                        
                        <input
                            className='setNumContextMetric'
                            type='number' 
                            step="any" 
                            min={Math.min(props.data.map(categories => categories[item]).sort()).toFixed(2)}
                            max={Math.max(...[...new Set(props.data.map(categories => categories[item]))].sort()).toFixed(2)}
                            // defaultValue={parseFloat(average([...new Set(props.data.map(categories => categories[item]))].filter(function( element ) {return element !== undefined;})).toFixed(2))}
                            value={props.contextualMetrics[item]}
                            onChange={event => saveContextCategoryNum(event, item)}
                        />
                        {/* <br/> */}
                    </div>
                }
     

                {/* </>
                ))} */}
                {/* <button className="calculationButton submitMetrics" onClick={saveAllMetrics}>Set Metrics</button> */}
                
                <div className='contextSelectionForm'>
                    <br/>
                    <Pagination
                        nPages={nPages}
                        currentPage={index}
                        setCurrentPage={setIndex}
                    />
                    <div className="goToMenu">
                    <GoTo 
                        list={contextMetricList}
                        setIndex={setIndex}/>
                    </div>
                </div>
                </>
                :
                <>
                    {/* <p>Great! We will set the metrics that make up your scenario now.</p>  */}
                    <p>We will start by selecting one metric that you will identify as your <strong>key metric</strong>.</p> 
                    <p>This is a metric where you’d like to see how different values or levels differentially impact your outcome.</p> 
                    <p>It most likely should be something you have control over. That way these different values can represent the various choices you can make, arming you with data to inform your actions.</p> 
                    <p>Voila! Now you have data driven action!</p>
                    <p>Please select your key metric. This will be a metric that you have control over.</p>
                    <label>key metric:</label>
                    <Select
                        isSearchable
                        options={props.metrics.sort().map(d => ({'value': d, 'label':d}))}
                        onChange={saveKeyMetric}
                        theme={style}
                        defaultValue={{'value': props.keyMetric, 'label': props.keyMetric}}
                        // placeholder='Select ...'
                    />
                    <br/>
                </>
                }
                </>
                :
                <>
                <p>Great, please use the forward arrow to use the default scenario in scenario modeling.</p>
                <p>You will be able to change the scenario later.</p>
                </>}
            </>
            :
            metrics.length === 1 ? 
            <>
            <p>You only have one metric - no need to set the scenario.</p> 
            <p>Please use the forward arrow to see your scenario modeling.</p>
            </>
            : 
            <>
                <p>Looking at our model we are able to see potential casual associations. If we make this adjustment to our metric, we can expect to see this change occur to our outcome. This is helpful information!</p> 
                <p>Sometimes, though, we might want to understand what our outcome might be under a predefined set of metrics, or a specific scenario. We can use our model to pose a scenario and then view the predicted outcome under that scenario.</p>
                <p>Let’s call this scenario modeling! We can now put our model to work by setting a scenario and reviewing potential associated outcomes.</p>
                <p>Would you like to set your own scenario, or use a default scenario to start?</p>
                <div className='scenarioOptions'>
                    <div className='scenarioOption1'>
                        <button className='calculationButton' 
                                onClick={setOwnScenario}>
                            <br/>
                            <FontAwesomeIcon icon={faPersonDigging}/>
                            <p>Set my own scenario.</p>
                        </button>
                    </div>
                    <div className='scenarioOption2'>
                        <button className='calculationButton' 
                                onClick={useDefaultScenario}>
                            <br/>
                            <FontAwesomeIcon icon={faPersonWalkingLuggage}/>
                            <p>Use a default scenario.</p>
                        </button>
                    </div>
                </div>
            </>}
        </>
    );
}

export default SetScenario;