import React, { useState } from 'react';
import Univariate from './Univariate';
import Multivariate from './Multivariate';
import ModelViewer from './ModelViewer';
import Pagination from './Pagination';
import GoTo from './GoTo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

function DescriptiveViewer(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(1);
    const [viewGoTo, setViewGoTo] = useState('No');
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecord = props.data.slice(indexOfFirstRecord, indexOfLastRecord)[0];
    const nPages = Math.ceil(props.data.length / recordsPerPage);
  return (
    <>
    {props.type == 'Descriptives' ? 
    <Univariate currentRecord={currentRecord}/>
    : null
    }
    {props.type == 'Correlations' ?
    <Multivariate currentRecord={currentRecord} requestdata={props.requestdata}/>
    : null}
    {props.type == 'Model' ?
    <ModelViewer calc={currentRecord} outcome={props.outcome}/>
    : null}
    <div className='contextSelectionForm'>
      <br/>
    <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
    <div className="goToMenu">
      { viewGoTo === 'No' ?
        <button  
          className='goTo'
          onClick={() => setViewGoTo('Yes')}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass}/>
        </button>
        :
        <>
        <GoTo 
          list={props.data.map(row =>row['metric'])}
          setIndex={setCurrentPage}
        />
        </>
      }
    </div>
    </div>
    </>

  )
}

export default DescriptiveViewer;
