// import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Components/Header'
import Footer from './Components/Footer'
import Introduction from './Components/Introduction'
import MainMenu from './Components/MainMenu'
import DataApp from './Components/DataApp'
import Canvas from "./Components/Canvas";
import './CSS/Main.css'

function App() {
  return (
    <div id='app-container'>
        <div id='app-wrap'>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Introduction/>} />
              <Route path='journey/' element={<Canvas/>} />

              <Route path='menu/' element={<MainMenu/>} />
              <Route path='menu/define_develop/' element={<DataApp/>} />
              {/* <Route path='menu/collect_cultivate/' element={<Canvas/>} /> */}

            </Routes>
          </BrowserRouter>
          {/* <Footer/> */}
        </div>
    </div>
  );
}

export default App;
