import React from 'react'
import Square from './Square'
import Header from './Header'
import '../CSS/Menu.css'

function MainMenu() {

  return(
    <>
      <Header/>
        
        <div className="parent">

          <Square className="div1"
                  link="define_develop"
                  heading1= 'define'
                  heading2='develop'
                  description='initial phases of purpose articulation + plan development'/>

          <Square className="div2"
                  link="collect_cultivate"
                  heading1= 'collect'
                  heading2='cultivate'
                  description='systematic data collection, processing + storage'/>

          <Square className="div3"
                  link="/"
                  heading1= 'discover'
                  heading2='disseminate'
                  description='address question(s) through analysis + communicate narrative'/>

        </div>
    </>
  )
};

export default MainMenu
