import React, { useState } from "react";
import GetColumns from './GetColumns'
// import useStickyState from "../Functions/useStickyState";
// import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import Header from "./Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowUpRightDots, faBasketShopping, faChartLine, faRuler, faTableList } from '@fortawesome/free-solid-svg-icons'

import ActionItem from './ActionItem';
import UploadData from './UploadData';
import GetCalculations from './GetCalculations';

import '../CSS/DataApp.css';

function DataApp() {
    const [data, setData] = useState();
    const [, setColumns] = useState();
    const [outcome, setOutcome] = useState();
    const [metrics, setMetrics] = useState();
    const [means, setMeans] = useState();
    const [correlations, setCorrelations] = useState();
    const [model, setModel] = useState();

    var payload = {};
    payload['metrics'] = metrics;
    payload['outcome'] = outcome;
    payload['data'] = data;

    // const navigate = useNavigate();
    // let navigate = useNavigate();
    // const handleClick = e => {
    //     e.preventDefault();
    //     navigate(-1);
    // }
    // const [data, setData] = useStickyState(null, 'data')

    return (
        <div className='shibani'>
        <Header/>
        <div className='content'>
            {/* <Histogram/> */}
            <ActionItem task='Get Data' icon={faBasketShopping} inactive={data ? false : true}>
                <UploadData setData={setData} data={data}/>
                {/* {data ? 'Data Uploaded!': 'Please upload your data.'} */}
            </ActionItem>

            <ActionItem task='Select Columns' icon={faTableList} inactive={metrics ? false : true}>
                {data ?   <GetColumns data={data} 
                            setColumns={setColumns}
                            setOutcome={setOutcome}
                            setMetrics={setMetrics}/> : <p>Please upload data prior to selecting your columns.</p>}

            </ActionItem>

            <ActionItem task='Calculate Descriptives' icon={faRuler} inactive={means ? false : true}>
                <GetCalculations post='http://127.0.0.1:8000/get_descriptives' payload={payload} setState={setMeans} type='Descriptives'/>    
            </ActionItem>

            <ActionItem task='Conduct Multivariate Analysis' icon={faArrowUpRightDots} inactive={correlations ? false : true}>
                <GetCalculations post='http://127.0.0.1:8000/get_multivariate' payload={payload} setState={setCorrelations} type='Correlations'/>    
            </ActionItem>

            {/* <ActionItem task='Calculate Means' icon={faRuler} inactive={means ? false : true}>
                <GetCalculations post='http://127.0.0.1:8000/get_means' payload={payload} setState={setMeans} type='Means'/>    
            </ActionItem>
            <ActionItem task='Calculate Correlations' icon={faArrowUpRightDots} inactive={correlations ? false : true}>
                <GetCalculations post='http://127.0.0.1:8000/get_correlations' payload={payload} setState={setCorrelations} type='Correlations'/>
            </ActionItem> */}
            <ActionItem task='Calculate Regression' icon={faChartLine} inactive={model ? false : true}>
                <GetCalculations post='http://127.0.0.1:8000/get_model' payload={payload} setState={setModel} type='Model'/>
            </ActionItem>
            <div className='back-arrow'>
                {/* <button><FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)}/></button> */}
                <NavLink to='/menu'>
                <button className='actionItemButton'><FontAwesomeIcon icon={faArrowLeft}/></button>
                </NavLink>
            </div>
        </div>
        </div>
    )
}

export default DataApp