import React from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const PDFDownloader = (props) => {

    const downloadPdfDocument = () => {

        const input = document.getElementById(props.rootElementId);
        const divInfo = document.getElementById(props.rootElementId).getBoundingClientRect();
        const divWidth = divInfo.width;
        const divHeight = divInfo.height;
        console.log(divWidth, divHeight);
        html2canvas(input, { 
            // scale: props.scale,
            scale: 3,
            letterRendering: true, 
            width: divWidth, 
            height: divHeight})
        // html2canvas(input, { scale: 1.5, width: 1024, height: 1448})

            .then((canvas) => {
                const imgData = canvas.toDataURL('image/svg', 1.0);
                // const pdf = new jsPDF('l', 'in', [11, 15]);
                const pdf = new jsPDF('l', 'mm', [divWidth, divHeight]);
                var docWidth = pdf.internal.pageSize.getWidth();
                var docHeight = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'JPEG', 0, 0, docWidth, docHeight);
                pdf.save(`${props.downloadFileName}.pdf`);
            });


            
        // html2canvas(input, {
        //     scale: 5,
        //   }).then(function (canvas) {
        //     var data = canvas.toDataURL("image/png");
        //     const pdf = new jsPDF('l', 'in', [11, 15]);
        //     pdf.addImage(data, "PNG", 0, 0);
        //     pdf.save("label.pdf");
        //   });
    }

    return  <button className='downloadButton' 
                    onClick={downloadPdfDocument}
                    title='Download Graphics as a PDF'>
                <FontAwesomeIcon icon={faDownload} />
            </button>

}

export default PDFDownloader;