import React, { useRef, useState } from 'react';
import * as xlsx from "xlsx";
import MovingText from 'react-moving-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import '../CSS/UploadData.css';

function UploadData (props) {
    const [progress, setProgress] = useState('');

    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    // const handleEvent = (event) => {
    //     setProgress(progress => progress += event.loaded);
    // };

    const loadData = (event) => {
        event.preventDefault();
        if (event.target.files) {
            const reader = new FileReader();
            const file = event.target.files[0];
            if (file.size <= 23736737) {
                setProgress('reading file . . . ')
                reader.readAsArrayBuffer(file);
                reader.onload = (event) => {
                    const data = event.target.result;
                    const workbook = xlsx.read(data, { type: "array", cellText: false, cellDates: true, });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = xlsx.utils.sheet_to_json(worksheet);
                    // const json = xlsx.utils.sheet_to_json(worksheet, { dateNF: 'yyyy"/"m"/"d',});
                    props.setData(json)
                    setProgress('Your file ' + file.name + ' is uploaded! Nice job.');
                    props.setColumns(false);
                    props.setOutcome(false);
                    props.setMetrics(false);
                };
            }

            else {
                setProgress('File too large! Please make it smaller.')
            }
        }
    };

    const handleChange = event => {
        event.preventDefault();
        loadData(event);
    };

    return (
        <>
        <div className='uploadgrid'>
            <div className='uploadbutton'>
                <button className='uploadFile'
                        onClick={handleClick}>
                    <br/>
                    <FontAwesomeIcon icon={faFile}/>
                    <p>upload a file</p>
                </button>
            </div>
        <div className='uploadmessage'>
            <p>{props.data ? props.afterMessage: props.priorMessage}</p>
        </div>
            <input
                className='button'
                type='file'
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{display:'none'}}
            />
        </div>
{progress === 'reading file . . . ' ?        
    <MovingText
            type="fadeOut"
            duration="2000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="infinite"
            fillMode="none">
        <p>{progress}</p>
    </MovingText>
    :
    <p>{progress}</p>}
        </>
    );
}

export default UploadData;