import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
import '../CSS/Footer.css';

function Footer() {
  let year = new Date().getFullYear();
  return (
  <footer className="footer">
      e v a l u t i o n  |  {year}  | <FontAwesomeIcon icon={faCopyright} style={{fontSize:"12px"}}/>
  </footer>
  )
  }

export default Footer
