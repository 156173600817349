import React, { useState } from "react";
import RefactoredBarChart from "./RefactoredBarChart";
import RefactoredScatterplot from "./RefactoredScatterplot";
import BarChart from './BarChart';
import ScatterPlot from "./ScatterPlot";
import PDFDownloader from "./PDFDownloader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import ToolTip from "./ToolTip";

function Multivariate(props) {
    const [viewToolTips, setViewToolTips] = useState(false);
    const toggleTipViewStyle = {display: viewToolTips ? '' : 'none'};

    const toggleTipView = () => {
        setViewToolTips(current => !current);
      };

    return (
        <>
        <div className='univariateTitle'>
                {/* <div className='univariateTitleMetric'>
                    <strong>{props.currentRecord.metric} x {props.currentRecord.outcome}</strong>
                </div> */}
                <button className='modelViewToolTips' 
                    onClick={toggleTipView}
                    title='View tips and helpful information'>
                    <FontAwesomeIcon icon={faLightbulb}/>
                </button>

                <div className='modelDownloader'>
                    <PDFDownloader 
                        downloadFileName={props.currentRecord.metric + ' x ' + props.currentRecord.outcome}
                        rootElementId="multivariateDownload" 
                    />
                </div>
                {/* <div className='univariateTitleViz'>
                    <p>Vizing out!</p>
                </div> */}
            </div>
            {/* <p>{props.currentRecord.metric} x {props.currentRecord.outcome}</p> */}
        <div id='multivariateDownload' className='displayResults'>
            <div className='displayResultsMetric1'>
                <p>
                    <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                        A measure of the strength and direction of the potential relationship between the metric and the outcome.
                    </ToolTip>
                    Coefficient:
                </p> 
                {props.currentRecord.Type === 'Correlation' ?
                <p>{parseFloat(props.currentRecord.Coefficient).toLocaleString("en-US", {maximumFractionDigits: 2})}</p>
                :
                <p>{parseFloat(props.currentRecord.EffectSize).toLocaleString("en-US", {maximumFractionDigits: 2})}</p>}
            </div>
            <div className='displayResultsMetric2'>
                    <p>
                        <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                            A measure of the likelihood that the relationship we see is random, and not something we would expect to see in the real world. Lower values indicate a higher likelihood that the relationship is real.
                        </ToolTip>
                        P-Value:
                    </p> 
                        <p>{props.currentRecord.PValue < 0.01 
                            ? 
                            <>Less than .01%</>
                            : 
                            <>{parseFloat(props.currentRecord.PValue).toLocaleString("en-US", {maximumFractionDigits: 3})}</>}</p>
            </div>
            <div className='displayResultsMetric3'>
                        <p>
                            <ToolTip toggleTipViewStyle={toggleTipViewStyle}>
                                An aribitrary threshold that uses the p-value at levels below the scientific standard of 5% or lower to determine if the relationship is something that is likely to actually exist. It is subjective and should be used for interpretation based on the context of your question.
                            </ToolTip>
                            Significant:
                        </p> 
                        <p>{props.currentRecord.Significant}</p>
            </div>
            <div className='displayResultsViz'>
                {props.currentRecord.Type === 'Correlation' ? 
                // <ScatterPlot data={props.requestdata} metric={props.currentRecord.metric} amount={props.currentRecord.outcome}/>
                <RefactoredScatterplot
                    title={props.currentRecord.outcome + ' and ' + props.currentRecord.metric}
                    tooltip='Each dot represents a row in your dataset, and its placement represents the value of the given metric and the outcome. (you can get a feel for the potential relationship by looking at the shape the dots make)'
                    toggleTipViewStyle={toggleTipViewStyle}
                    data={props.requestdata}
                    metric={props.currentRecord.metric} 
                    amount={props.currentRecord.outcome}
                />
                :
                // <p>Placeholder! :)</p>
                // {props.currentRecord.Type}
                // <BarChart data={JSON.parse(props.currentRecord.CrossTab)} metric={props.currentRecord.metric} amount={props.currentRecord.outcome}/>
                <RefactoredBarChart 
                    title={props.currentRecord.outcome + ' at Levels of ' + props.currentRecord.metric}
                    tooltip='What your outcome variable looks like at varying levels of your metric. (does the outcome look the same, or does it look differently accross values of your metric?)'
                    toggleTipViewStyle={toggleTipViewStyle}
                    data={JSON.parse(props.currentRecord.CrossTab)} 
                    metric={props.currentRecord.metric} 
                    amount={props.currentRecord.outcome} 
                    total={props.currentRecord.totalRecords}
                    type={props.currentRecord.Type}
                />
                // <p>{JSON.stringify(JSON.parse(props.currentRecord.CrossTab))}</p>
                }
            </div>
        </div>
        </>
    )
};

export default Multivariate;