import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link} from "react-router-dom";
import '../CSS/Introduction.css'

  const messages = [
    'evalution is growth + progress through data',
    'data is how we articulate what we care about',
    '+ align our actions with our shared values.',
    'this is a process, we go through cycles of learning and improving',
    'we define our purpose and most important questions',
    'we quantify our outcomes and develop a plan for measurement',
    'we collect the information we will need to answer our questions',
    'not unlike gardening, we cultivate our data so it is ripe for analysis',
    'we analyze our data, discovering new insights and new questions',
    'we disseminate our findings for collective action',
    'and then we start the process all over again!',
    "let's get started together",
  ];


function Introduction() {
  const [index, setIndex] = useState(0);

  useEffect(() => {if (index < 11){
    const interval = setInterval(() => {
      setIndex(index => index + 1);
    }, 2500);
    return () => clearInterval(interval);
  }});

  return(
    <div className="introduction">
      <div className="welcome">
        <h1>welcome.</h1> 
        <h3>{messages[index]}</h3>
        <div className="start" >
          {/* <Link className="forward" to="menu"> */}
          <Link className="forward" to="journey">
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Introduction