import React from "react";

function at(n) {
    // ToInteger() abstract op
    n = Math.trunc(n) || 0;
    // Allow negative indexing from the end
    if (n < 0) n += this.length;
    // OOB access is guaranteed to return undefined
    if (n < 0 || n >= this.length) return undefined;
    // Otherwise, this is just normal property access
    return this[n];
}

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const C of [Array, String, TypedArray]) {
    Object.defineProperty(C.prototype, "at",
                          { value: at,
                            writable: true,
                            enumerable: false,
                            configurable: true });
}
function PaginationMenu(props) {
    const pageNumbers = [...Array(props.nPages + 1).keys()].slice(1);
    
    const nextPage = () => {
        if(props.currentPage !== props.nPages) 
            props.setCurrentPage(props.currentPage + 1)
    };
    
    const prevPage = () => {
        if(props.currentPage !== 1) 
            props.setCurrentPage(props.currentPage - 1)
    };

    return(
        <div className='recordNav'>
   <nav>
        <ul className='pagination'>
            <li className="page-item">
                <a className="page-link" 
                    onClick={prevPage} 
                    href='#'>
                    Previous
                </a>
            </li>

            {(![1,2].includes(props.currentPage))
            ?
            <li key={pageNumbers[0]} 
                className= {`page-item ${props.currentPage === pageNumbers[0] ? 'active' : ''} `} >
                <a onClick={() => props.setCurrentPage(pageNumbers[0])}  
                    className='page-link' 
                    href='#'>
                    {pageNumbers[0]}
                </a>
            </li>
            :
            null}

            {(![1,2,3].includes(props.currentPage))
            ? 
            <li className="page-item">
                . . .
            </li> 
            : 
            null}

            {pageNumbers.slice(props.currentPage-2, props.currentPage-1).map(pageNumber => (
                <li key={pageNumber} 
                    className= {`page-item ${props.currentPage === pageNumber ? 'active' : ''} `} >
                    <a onClick={() => props.setCurrentPage(pageNumber)}  
                        className='page-link' 
                        href='#'>
                        {pageNumber}
                    </a>
                </li>
            ))}

            <li className="page-item ">
                <a className='page-link'>
                    <strong className="page-item-selected">{props.currentPage}</strong>
                </a>
            </li>

            {pageNumbers.slice(props.currentPage, props.currentPage+1).map(pageNumber => (
                <li key={pageNumber} 
                    className= {`page-item ${props.currentPage === pageNumber ? 'active' : ''} `} >
                    <a onClick={() => props.setCurrentPage(pageNumber)}  
                        className='page-link' 
                        href='#'>
                        {pageNumber}
                    </a>
                </li>
            ))}

            {(![pageNumbers.at(-1), pageNumbers.at(-2), pageNumbers.at(-3)].includes(props.currentPage))
                ? 
                <li className="page-item">
                    . . .
                </li> 
                : 
                null}

            {(![pageNumbers.at(-1), pageNumbers.at(-2)].includes(props.currentPage))
                ?
                <li key={pageNumbers.at(-1)} 
                className= {`page-item ${props.currentPage === pageNumbers.at(-1) ? 'active' : ''} `} >
                <a onClick={() => props.setCurrentPage(pageNumbers.at(-1))}  
                    className='page-link' 
                    href='#'>
                    {pageNumbers.at(-1)}
                </a>
            </li>
                :
                null
            }


            <li className="page-item">
                <a className="page-link" 
                    onClick={nextPage}
                    href='#'>
                    Next
                </a>
            </li>
        </ul>
    </nav>
    </div>
    );
};

export default PaginationMenu;