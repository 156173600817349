import React from 'react';

function StepTitle(props) {
  return (
    <>
    <h1 className='stepTitle'>{props.step ? <span className='stepNumber'>{props.step}</span> : null}{props.message}</h1>
    <hr/>
    <br/>
    </>
  )
}

export default StepTitle;