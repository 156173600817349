import React, { useState } from 'react';
import { scaleBand, scaleLinear, max, format, extent } from 'd3';
import '../CSS/BarChart.css';
import ToolTip from './ToolTip';

const width = 650;
const height = 500;
const margin = {
        top: 20,
        right: 50,
        bottom: 70,
        left: 200,
    };
const innerHeight = height - margin.top - margin.bottom;
const innerWidth = width - margin.left - margin.right;
const xAxisLabelOffset = 40;
const fadeOpacity = .2;

function RefactoredBarChart(props) {
    const [hoveredValue, setHoveredValue] = useState(null);
    const yValue = (d) => d[props.metric];
    const xValue = (d) => d[props.amount];

    const yScale = scaleBand()
                    .domain(props.data.map(yValue))
                    .range([0, innerHeight])
                    .paddingInner(0.15);
  
    const xScale = scaleLinear()
                    // .domain([0, max(props.data, xValue)])
                    .domain(extent(props.data, xValue))
                    .range([0, innerWidth])
                    .nice();

    const filteredData = props.data.filter((d) => hoveredValue === yValue(d));
    
    var totalOutcome = 0;
    for (var i = 0; i < props.data.length; i++) {
        totalOutcome += props.data[i][props.amount]
    }
    
    const xAxisTickFormat = format(',');
    const labelFormat = format('.0%');
    const anovaLabelFormat = format(',.0f');
    const predictionLabelFormat = format(',.3f');
    const logitPredictionLabelFormat = format('.3%');

    return (
        <div className="svg-container">
            <br/>
            {props.tooltip ?             
            <ToolTip toggleTipViewStyle={props.toggleTipViewStyle}>
                {props.tooltip}
            </ToolTip>
            :
            null }
            <span className='viz-title'>{props.title}</span>
        <svg width={width} height={height}  version="1.1" viewBox="0 0 650 500"  preserveAspectRatio="xMinYMin meet"  className="svg-content">
            <g transform={`translate(${margin.left},${margin.top})`}>
                // X-Axis
                {xScale.ticks(4).map((tickValue) => (
                    <g  className='tick'
                        key={tickValue}
                        transform={`translate(${xScale(tickValue)},0)`}
                    >
                        <line   y2={innerHeight} />
                        <text   style={{ textAnchor: 'middle' }}
                                y={innerHeight + 3}
                                dy='.71em'
                        >
                            {props.type === 'logit' ? logitPredictionLabelFormat(tickValue) : xAxisTickFormat(tickValue)}
                            
                        </text>
                    </g>
                ))}
                // Y-Axis
                {yScale.domain().map((tickValue) => (
                    <g 
                        className="category"
                        key={tickValue}
                    >
                        <text
                            style={{ textAnchor: 'end' }}
                            dy=".32em"
                            x={-7.5}
                            y={yScale(tickValue) + yScale.bandwidth() / 2}
                            onMouseEnter={() => {setHoveredValue(tickValue);}}
                            onMouseOut={() => {setHoveredValue(null);}}
                            opacity={hoveredValue && tickValue !== hoveredValue ? fadeOpacity : 1}
                        >
                            <title>{tickValue}</title>
                            {tickValue.length > 16 ? tickValue.slice(0, 16) + '. . .' : tickValue}

                        </text>
                    </g>
                ))}
                // X-Axis Label
                <text   className='axis-label'
                        x={innerWidth / 2}
                        textAnchor='middle'
                        y={innerHeight + xAxisLabelOffset}
                    >
                    {props.amount === 'Count' ? 'Number of Records' : props.type === 'logit' ? <>Likelihood of {props.outcome}</>: props.type === 'ols' ? <>Predicted  {props.outcome}</> : props.amount}
                    
                </text>
                // All Bars
                {props.data.map((d) => (
                    <rect
                        opacity={hoveredValue ? fadeOpacity : 1}
                        className='mark'
                        key={yValue(d)}
                        x={0}
                        y={yScale(yValue(d))}
                        width={xScale(xValue(d))}
                        height={yScale.bandwidth()}
                        onMouseEnter={() => {setHoveredValue(yValue(d));}}
                        onMouseOut={() => {setHoveredValue(null);}}
                    />
                    )
                )}
                // Selected Bars
                {filteredData.map((d) => (
                    <g key={yValue(d)}>
                        <rect
                            className='mark'
                            x={0}
                            y={yScale(yValue(d))}
                            width={xScale(xValue(d))}
                            height={yScale.bandwidth()}
                            onMouseEnter={() => {setHoveredValue(yValue(d));}}
                            onMouseOut={() => {setHoveredValue(null);}}
                        >
                            <title>{yValue(d)}</title>
                        </rect>
                        <text 
                            className='barHoverLabel'
                            y={yScale(yValue(d))+ yScale.bandwidth()/2}
                            x={xScale(xValue(d))+ 10}
                            dy=".32em"
                            onMouseEnter={() => {setHoveredValue(yValue(d));}}
                            onMouseOut={() => {setHoveredValue(null);}}
                        >
                            {props.amount === 'Predictions' ?
                            props.type === 'logit' ? logitPredictionLabelFormat(xValue(d)) : predictionLabelFormat(xValue(d))
                            :
                            props.type === 'ANOVA' ? anovaLabelFormat(xValue(d)) : labelFormat(props.type === 'Chi-Square' ? xValue(d)/totalOutcome : xValue(d)/props.total)
                            }   
                        </text>
                    </g>
                    )
                )}
            </g>
        </svg>
        </div>
    );
}

export default RefactoredBarChart;