import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import '../CSS/ActionItem.css';

function ActionItem(props) {
    const [show, setShow] = useState(false);
 
    return (
        <div className='container'>
            <div className="leftmargin"> </div>
            <div className="rightmargin"> </div>
            <div className="check">
                <FontAwesomeIcon className={`checkmark ${props.inactive ? "inactive" : ""}`} icon={ faSquareCheck } />
            </div>
            <div className="action"> 
                <button className='actionItemButton' onClick={() => setShow(!show)}> 
                    <FontAwesomeIcon className='icon' icon={ props.icon } />
                </button>
            </div>
            <div className="title"> 
                <h2>{props.task}</h2>
            </div>
            <div className="leftblank"> </div>
            <div className="rightblank"> </div>
            <div className="task">
                {show ? props.children : null}
            </div>
        </div>
    );
}

export default ActionItem;