import React, { useState } from "react";
import { scaleLinear, extent, format, line, curveNatural } from "d3";

const width = 650;
const height = 500;
const margin = {
        top: 30,
        right: 60,
        bottom: 50,
        left: 200,
    };
const innerHeight = height - margin.top - margin.bottom;
const innerWidth = width - margin.left - margin.right;
const xAxisLabelOffset = 40;
const tickOffset = 7.5;
const fadeOpacity = .2;
function RefactoredLineGraph(props) {
    const [hoveredValue, setHoveredValue] = useState(null);
    
    const yValue = (d) => d[props.amount];
    const xValue = (d) => d[props.metric];
    
    const xScale = scaleLinear()
        .domain(extent(props.data, xValue))
        // .domain([0,max(props.data, xValue)])
        .range([0, innerWidth])
        .nice();
        
    const yScale = scaleLinear()
        .domain(extent(props.data, yValue))
        // .domain([0,max(props.data, yValue)])
        .range([innerHeight, 0])
        .nice();

    const digits = Math.max(...(yScale.domain().map(item => item.toString().length)));
    const yAxisLabelOffset = (25+(digits*10));
    const xAxisTickFormat = format(',');
    const yAxisTickFormat = format('.2%');
    const xValueLabelFormat = format('.2f');
    for (var i = 0; i < props.data.length; i++) {
        props.data[i].row_number = i;
      }
    const filteredData = props.data.filter(d => hoveredValue === xValue(d));
    return (
        <div className="svg-container-scenario">
        {/* <div className='scenario-viz-title'></div> */}
        <svg width={width} height={height}  version="1.1" viewBox="0 0 650 500"  preserveAspectRatio="xMinYMin meet"  className="svg-content">
            <text
                x={innerWidth}
                y={18}
                textAnchor='middle'
                className="axis-label"
                
            >
                {props.title}
            </text>
        {/* <svg width={width} height={height}> */}
            <g transform={`translate(${margin.left},${margin.top})`}>
                {// X-Axis
                xScale.ticks(4).map((tickValue) => (
                    <g 
                        className='tick'
                        key={tickValue}
                        transform={`translate(${xScale(tickValue)},0)`}
                    >
                        <line   y2={innerHeight} />
                        <text   style={{ textAnchor: 'middle' }}
                                y={innerHeight + 3}
                                dy='.71em'
                        >
                            {xAxisTickFormat(tickValue)}                            
                        </text>
                    </g>
                ))}
                {// Y-Axis
               yScale.ticks(4).map(tickValue => (
                    <g 
                        key={tickValue}
                        className="tick" 
                        transform={`translate(0,${yScale(tickValue)})`}
                    >
    	                <line x2={innerWidth}/>
                        <text
                            key={tickValue}
                            style={{ textAnchor: 'end' }}
                            dy=".32em"
                            x={-tickOffset}
                        >
                            {props.type === 'ols' ? xAxisTickFormat(tickValue):yAxisTickFormat(tickValue)} 
                        </text>
                    </g>
                ))
                // X-Axis Label
               }
                <text   className='axis-label'
                        x={innerWidth / 2}
                        textAnchor='middle'
                        y={innerHeight + xAxisLabelOffset}
                    >
                    {props.metric}
                </text>

                {// Y-Axis Label
                }
                <text
                    className="axis-label"
                    textAnchor="middle"
                    transform={`translate(${-yAxisLabelOffset}, ${innerHeight / 2}) rotate(-90)`}
                >
                    {props.type === 'ols' ? <>Predicted </> : <>Likelihood of </>}{props.outcome}
                </text>
                {// All Circles
                props.data.map(d => (
                    <g className="marks" key={xValue(d)}>
                        <path
                            className="scenarioLine"
                            d={line()
                                .curve(curveNatural)
                                .x((d) => xScale(xValue(d)))
                                .y((d) => yScale(yValue(d)))(props.data)}
                        />
                        <circle
                            opacity={hoveredValue ? fadeOpacity : 1}
                            className='bar'
                            cx={xScale(xValue(d))}
                            cy={yScale(yValue(d))}
                            r={4}
                            onMouseEnter={() => {setHoveredValue(xValue(d));}}
                            onMouseOut={() => {setHoveredValue(null);}}
                        />
                  </g>
                ))}
                {// Selected Circle
                filteredData.map((d) => (
                    <g key={xValue(d)}>
                        <circle
                            className='bar'
                            cx={xScale(xValue(d))}
                            cy={yScale(yValue(d))}
                            r={7}
                            onMouseEnter={() => {setHoveredValue(xValue(d));}}
                            onMouseOut={() => {setHoveredValue(null);}}
                        />
                        <text 
                            className='barHoverLabel'
                            x={xScale(xValue(d))}
                            y={yScale(yValue(d))- 30}
                            dy=".32em"
                            textAnchor='middle'
                            onMouseEnter={() => {setHoveredValue(d.x1);}}
                            onMouseOut={() => {setHoveredValue(null);}}
                        >
                            <tspan
                                x={xScale(xValue(d))}
                                y={yScale(yValue(d))- 75}
                            >
                                {props.metric}: {xValueLabelFormat(xValue(d))}
                            </tspan>
                            <tspan
                                x={xScale(xValue(d))}
                                y={yScale(yValue(d))- 50}
                            >
                                {/* {props.amount}: {yAxisTickFormat(yValue(d))} */}
                                {/* Prediction: {yAxisTickFormat(yValue(d))} */}
                                Prediction: {props.type === 'ols' ? xValueLabelFormat(yValue(d)):yAxisTickFormat(yValue(d))}
                            </tspan>
                        </text>
                    </g>
                ))}
            </g>
        </svg>
        </div>
    )
};

export default RefactoredLineGraph;