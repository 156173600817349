import React, { useState } from "react";
import { scaleLinear, extent, format } from "d3";
import ToolTip from './ToolTip';

const width = 650;
const height = 500;
const margin = {
        top: 40,
        right: 60,
        bottom: 70,
        left: 200,
    };
const innerHeight = height - margin.top - margin.bottom;
const innerWidth = width - margin.left - margin.right;
const xAxisLabelOffset = 40;
const tickOffset = 7.5;
const fadeOpacity = .2;
function RefactoredScatterplot(props) {
    const [hoveredValue, setHoveredValue] = useState(null);
    
    const xValue = (d) => d[props.metric];
    const yValue = (d) => d[props.amount];
    
    const xScale = scaleLinear()
        .domain(extent(props.data, xValue))
        .range([0, innerWidth])
        .nice();
        
    const yScale = scaleLinear()
        .domain(extent(props.data, yValue))
        .range([innerHeight, 0])
        .nice();

    const digits = Math.max(...(yScale.domain().map(item => item.toString().length)));
    const yAxisLabelOffset = (25+(digits*10));
    const xAxisTickFormat = format(',');
    for (var i = 0; i < props.data.length; i++) {
        props.data[i].row_number = i;
      }
    const filteredData = props.data.filter(d => hoveredValue === d.row_number);
    return (
        <div className="svg-container">
            <br/>
            {props.tooltip ?             
            <ToolTip toggleTipViewStyle={props.toggleTipViewStyle}>
                {props.tooltip}
            </ToolTip>
            :
            null }
            <span className='viz-title'>{props.title}</span>
        <svg width={width} height={height}  version="1.1" viewBox="0 0 650 500"  preserveAspectRatio="xMinYMin meet"  className="svg-content">
            <g transform={`translate(${margin.left},${margin.top})`}>
                {//X-Axis
                xScale.ticks(4).map((tickValue) => (
                    <g 
                        className='tick'
                        key={tickValue}
                        transform={`translate(${xScale(tickValue)},0)`}
                    >
                        <line   y2={innerHeight} />
                        <text   style={{ textAnchor: 'middle' }}
                                y={innerHeight + 3}
                                dy='.71em'
                        >
                            {xAxisTickFormat(tickValue)}                            
                        </text>
                    </g>
                ))}
               { // Y-Axis
               yScale.ticks(4).map(tickValue => (
                    <g 
                        className="tick" 
                        transform={`translate(0,${yScale(tickValue)})`}
                    >
    	                <line x2={innerWidth}/>
                        <text
                            key={tickValue}
                            style={{ textAnchor: 'end' }}
                            dy=".32em"
                            x={-tickOffset}
                        >
                            {xAxisTickFormat(tickValue)} 
                        </text>
                    </g>
                ))}
                {// X-Axis Label
                }
                <text   className='axis-label'
                        x={innerWidth / 2}
                        textAnchor='middle'
                        y={innerHeight + xAxisLabelOffset}
                    >
                    {props.metric}
                </text>
                {// Y-Axis Label
                }
                <text
                    className="axis-label"
                    textAnchor="middle"
                    transform={`translate(${-yAxisLabelOffset}, ${innerHeight / 2}) rotate(-90)`}
                >
                    {props.amount}
                </text>
                {// All Circles
                props.data.map(d => (
                    <circle
                        opacity={hoveredValue ? fadeOpacity : 1}
                        key={d.row_number}
                        className='bar'
                        cx={xScale(xValue(d))}
                        cy={yScale(yValue(d))}
                        r={7}
                        onMouseEnter={() => {setHoveredValue(d.row_number);}}
                        onMouseOut={() => {setHoveredValue(null);}}
                    />
                ))}
                {// Selected Circle
                filteredData.map((d) => (
                    <g key={d.row_number}>
                        <circle
                            key={d.row_number}
                            className='bar'
                            cx={xScale(xValue(d))}
                            cy={yScale(yValue(d))}
                            r={7}
                            onMouseEnter={() => {setHoveredValue(d.row_number);}}
                            onMouseOut={() => {setHoveredValue(null);}}
                        />
                        <text 
                            className='barHoverLabel'
                            x={xScale(xValue(d))}
                            y={yScale(yValue(d))- 30}
                            dy=".32em"
                            textAnchor='middle'
                            onMouseEnter={() => {setHoveredValue(d.x1);}}
                            onMouseOut={() => {setHoveredValue(null);}}
                        >
                            <tspan
                                x={xScale(xValue(d))}
                                y={yScale(yValue(d))- 50}
                            >
                                {props.metric}: {xAxisTickFormat(xValue(d))}
                            </tspan>
                            <tspan
                                x={xScale(xValue(d))}
                                y={yScale(yValue(d))- 20}
                            >
                                {props.amount}: {xAxisTickFormat(yValue(d))}
                            </tspan>
                        </text>
                    </g>
                ))}
            </g>
        </svg>
        </div>
    )
};

export default RefactoredScatterplot;