import React, {useState} from "react";
import Select from 'react-select';

function GoTo(props) {
    const [selectedMetric, setSelectedMetric] = useState('Go To ...')
    const columns = props.list.map(d => ({'value': d, 'label':d}))
    const style = (theme) => ({
        ...theme,
        borderRadius: 8,
        // spacing: {
        //     baseUnit: 10,
        //     controlHeight: 40,
        //     menuGutter: 20,
        // },
          control: (base) => ({
            ...base,
            boxShadow: "none",
            minHeight: 10,
            height: 10,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          clearIndicator: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
          }),
        colors: {
            ...theme.colors,
            neutral90: '#7C6674',
            primary25: '#e0dade',
            primary: '#7C6674',
            neutral10:'#e0dade',
            neutral80: '#7C6674',
            danger:'#e0dade',
            dangerLight: '#7C6674',
        },
      });

      const saveOutcome = (event) => {
        console.log(props.list.indexOf(event.value));
        console.log(event.value);
        props.setIndex(props.list.indexOf(event.value)+1);
        // props.setOutcome(event.value);
        // setOutcome(event.value);
        // setMetricOptions(columns.filter(x => x.value !== event.value));
        // setProgress('metrics');
    }

    return (
        <>
        {/* {props.list ? 'Yes' : 'No'}
        {JSON.stringify(props.list)} */}
        {/* <label>Go To:</label> */}
        <Select 
            // className="goToMenu"
            options={columns}
            onChange={saveOutcome}
            theme={style}
            // defaultValue = {defaultOutcome}
            placeholder = 'Go To ...'
            menuPlacement = 'top'
            // maxMenuHeight = '1vh'
            // value={selectedMetric}
                                
                        />
        </>
    )
}

export default GoTo;