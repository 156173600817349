import React, { useState } from 'react';
import RefactoredLineGraph from './RefactoredLineGraph';
import RefactoredBarChart from './RefactoredBarChart';
import ScenarioModelingUpdate from './ScenarioModelingUpdate';
import ScenarioModelingView from './ScenarioModelingView';
import PDFDownloader from "./PDFDownloader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faBinoculars } from '@fortawesome/free-solid-svg-icons'

function ScenarioModeling(props) {
    const [data, setData] = useState(props.data);
    const [viewMode, setViewMode] = useState(true);

    // const toggleScenarioViewMode()
    return (
        <>

        <div className='modelViewToolTips'>
            { Object.keys(props.contextualMetrics).length > 0 ?
            <button className='downloadButton' 
                    onClick={() => setViewMode(previous => !previous)}
                    title='View/Edit the scenario'>
                    <FontAwesomeIcon icon={viewMode ? faPen : faBinoculars}/>
            </button>
            : null
            }
        </div>

        <div className='modelDownloader'>
            <PDFDownloader 
                downloadFileName={props.outcome + ' Scenario Modeling'}
                rootElementId='scenarioDownload'
            />
        </div>

        <div id='scenarioDownload' className='scenarioModelingGrid'>
            { viewMode ?
                <ScenarioModelingView
                    requestdata={props.requestdata}
                    metrics={props.metrics}
                    keyMetric={props.keyMetric}
                    contextualMetrics={props.contextualMetrics}
                    setKeyMetric={props.setKeyMetric}
                    setContextualMetrics={props.setContextualMetrics}
                    data={data}
                    setData={setData}
                    post={props.post}
                    payload={props.payload}
                />
            :
                <ScenarioModelingUpdate
                    requestdata={props.requestdata}
                    metrics={props.metrics}
                    keyMetric={props.keyMetric}
                    contextualMetrics={props.contextualMetrics}
                    setKeyMetric={props.setKeyMetric}
                    setContextualMetrics={props.setContextualMetrics}
                    data={data}
                    setData={setData}
                    post={props.post}
                    payload={props.payload}
                />
            }
            

            <div className='scenarioModelingVisualization'>
                    {data.KeyMetricType === 'int' ? 
                        <RefactoredLineGraph
                            title={props.outcome + ' at Levels of ' + data.KeyMetric}
                            data={data.Scenarios}
                            metric={data.KeyMetric} 
                            amount='Predictions'
                            outcome={props.outcome}
                            type={data.ModelType}
                        />
                        :
                        <RefactoredBarChart 
                            title={props.outcome + ' at Levels of ' + data.KeyMetric}
                            data={data.Scenarios} 
                            metric={data.KeyMetric} 
                            amount='Predictions'
                            outcome={props.outcome}
                            type={data.ModelType}
                        />
                    }
            </div>
        </div>
        </>     
    );
}

export default ScenarioModeling;